import React from 'react'
import { Button, Text, Flex, useToast } from '@chakra-ui/react'
import { navigate } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import Modal from './modal'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { errorToastOptions } from '../helpers'

import { useMutation, useQueryClient } from '@tanstack/react-query'

import { joinTeam } from '../services/team'

import { object, string } from 'yup'
import Input from './input'

const SelectTeamModal = ({
	isOpen,
	setIsOpen,
	toggledMembers,
	setToggledMembers,
	teams = []
}) => {
	const toast = useToast()
	const queryClient = useQueryClient()

	const joinTeamMutation = useMutation(joinTeam, {
		onSuccess: async (data) => {
			// Joined team
			queryClient.invalidateQueries(['groupTeams'])
			setIsOpen(false)
			setToggledMembers([])
		},
		onError: (error) => {
			toast({
				description: error.message || error,
				...errorToastOptions
			})
		}
	})

	return (
		<Modal
			isPink
			title={'Assign Team'}
			opened={isOpen}
			onClose={() => setIsOpen(false)}
		>
			<Flex flexDir={'column'} gap={5}>
				<Text
					fontSize={16}
					fontWeight={'500'}
					px="20px"
					textAlign="center"
					color="black"
				>
					Select the team you want to move the {toggledMembers.length}{' '}
					player{toggledMembers.length > 1 ? 's' : ''} to.
				</Text>

				{teams.map((t) => (
					<Button
						variant="secondary"
						isLoading={joinTeamMutation.isLoading}
						onClick={() => {
							joinTeamMutation.mutate({
								teamId: t.id,
								members: toggledMembers
							})
						}}
						key={t.id}
					>
						{t.name}
					</Button>
				))}
			</Flex>
		</Modal>
	)
}

export default SelectTeamModal
